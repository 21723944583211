import { getAksesByUser } from "@/api/MenuApi";
import { createRouter, createWebHistory } from "vue-router";

const routes = [
  // Landingpage
  {
    path: "/",
    name: "landingpage",
    component: () => import("@/views/landingpage/index/Landingpage.vue"),
    meta: {
      layout: "blank",
    },
  },
  // Landingpage informasi
  {
    path: "/informasitender",
    name: "landingpagetender",
    component: () => import("@/views/landingpage/index/LandingpageTender.vue"),
    meta: {
      layout: "blank",
    },
  },
  {
    path: "/informasitender/:id",
    name: "landingpagetenderdetail",
    component: () => import("@/views/landingpage/index/LandingpageTenderDetail.vue"),
    meta: {
      layout: "blank",
    },
  },
  {
    path: "/informasiberita",
    name: "landingpageberita",
    component: () => import("@/views/landingpage/index/LandingpageBerita.vue"),
    meta: {
      layout: "blank",
    },
  },
  {
    path: "/informasiberita/:id",
    name: "landingpageberitadetail",
    component: () => import("@/views/landingpage/index/LandingpageBeritaDetail.vue"),
    meta: {
      layout: "blank",
    },
  },
  {
    path: "/informasiLayanan/:id",
    name: "landingpagelayanandetail",
    component: () => import("@/views/landingpage/index/LandingpageLayananDetail.vue"),
    meta: {
      layout: "blank",
    },
  },
  {
    path: "/informasiCsr/:id",
    name: "landingpagecrsdetail",
    component: () => import("@/views/landingpage/index/LandingpageCSRDetail.vue"),
    meta: {
      layout: "blank",
    },
  },
  // {
  //   path: "/kurs",
  //   name: "kurs",
  //   component: () => import("@/views/landingpage/bisnis/sukubunga/Kurs.vue"),
  //   meta: {
  //     layout: "blank",
  //   },
  // },

  // Ebanking
  {
    path: "/ebanking",
    name: "landingpageebanking",
    component: () => import("@/views/landingpage/ebanking/Ebanking.vue"),
    meta: {
      layout: "blank",
    },
  },

  // Bisnis
  {
    path: "/bisnis",
    name: "landingpagebisnis",
    component: () => import("@/views/landingpage/bisnis/tabungan/Bisnis.vue"),
    meta: {
      layout: "blank",
    },
  },
  {
    path: "/bisnis/sukubunga",
    name: "landingpagesukubunga",
    component: () => import("@/views/landingpage/bisnis/sukubunga/Sukubunga.vue"),
    meta: {
      layout: "blank",
    },
  },
  {
    path: "/bisnis/pinjaman",
    name: "landingpagpinjaman",
    component: () => import("@/views/landingpage/bisnis/pinjaman/Pinjaman.vue"),
    meta: {
      layout: "blank",
    },
  },
  {
    path: "/bisnis/layanan",
    name: "landingpaglayanan",
    component: () => import("@/views/landingpage/bisnis/layanan/Layanan.vue"),
    meta: {
      layout: "blank",
    },
  },

  // TENTANG PERUSAHAAN
  {
    path: "/perusahaan",
    name: "tentang-perusahaan",
    component: () => import("@/views/landingpage/perusahaan/Tentang.vue"),
    meta: {
      layout: "blank",
    },
  },
  {
    path: "/perusahaan/csr",
    name: "csr-perusahaan",
    component: () => import("@/views/landingpage/perusahaan/csr/Csr.vue"),
    meta: {
      layout: "blank",
    },
  },
  {
    path: "/perusahaan/investor",
    name: "investor-perusahaan",
    component: () => import("@/views/landingpage/perusahaan/investor/Hubungan.vue"),
    meta: {
      layout: "blank",
    },
  },
  {
    path: "/perusahaan/tatakelola",
    name: "tata-kelola-perusahaan",
    component: () => import("@/views/landingpage/perusahaan/tatakelola/TataKelola.vue"),
    meta: {
      layout: "blank",
    },
  },

  // Jaringan
  {
    path: "/jaringankantor",
    name: "landingpagjaringankantor",
    component: () => import("@/views/landingpage/jaringankantor/JaringanKantor.vue"),
    meta: {
      layout: "blank",
    },
  },

  // Simulasi
  {
    path: "/simulasi",
    name: "simulasi",
    component: () => import("@/views/landingpage/simulasi/Simulasi.vue"),
    meta: {
      layout: "blank",
    },
  },

  // Penghargaan

  {
    path: "/penghargaan",
    name: "landingpagepenghargaan",
    component: () => import("@/views/landingpage/perusahaan/Penghargaan.vue"),
    meta: {
      layout: "blank",
    },
  },

  {
    path: "/isipenghargaan",
    name: "landingpageisipenghargaan",
    component: () => import("@/views/landingpage/perusahaan/Isipenghargaan.vue"),
    meta: {
      layout: "blank",
    },
  },

  // Karir
  {
    path: "/karir",
    name: "landingpagekarir",
    component: () => import("@/views/landingpage/karir/Karir.vue"),
    meta: {
      layout: "blank",
    },
  },
  {
    path: "/karir/artikel",
    name: "landingpageartikel",
    component: () => import("@/views/landingpage/karir/ArtikelKarir.vue"),
    meta: {
      layout: "blank",
    },
  },

  {
    path: "/:pathMatch(.*)*",
    name: "error",
    component: () => import("@/views/landingpage/Error.vue"),
    meta: {
      layout: "blank",
    },
  },
  // LOGIN CMS
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/dashboard/cms/Login.vue"),
    meta: {
      layout: "blank",
    },
  },
  {
    path: "/logout",
    name: "logout",
    component: () => import("@/views/dashboard/cms/Logout.vue"),
    meta: {
      layout: "blank",
    },
  },
  // Dashboard
  {
    path: "/dashboard",
    name: "dashboard",
    component: () => import(/* webpackChunkName: "dashboard" */ "../views/dashboard/Dashboard.vue"),
    meta: {
      menu: "Home",
      requiresAuth: true,
      // akses: "dashboard",
    },
  },
  // Informasi Dasar
  {
    path: "/cms/informasi",
    name: "cms-informasi",
    component: () => import(/* webpackChunkName: "informasi" */ "../views/dashboard/cms/Informasi.vue"),
    meta: {
      menu: "Informasi Umum",
      requiresAuth: true,
      akses: "cms-informasi",
    },
  },
  {
    path: "/cms/slider",
    name: "cms-slider",
    component: () => import(/* webpackChunkName: "slider" */ "../views/dashboard/cms/Slider.vue"),
    meta: {
      menu: "Slider Landingpage",
      requiresAuth: true,
      akses: "cms-slider",
    },
  },
  {
    path: "/cms/slider/form",
    name: "cms-slider-form",
    component: () => import(/* webpackChunkName: "cms-slider-form" */ "../views/dashboard/cms/SliderForm.vue"),
    meta: {
      requiresAuth: true,
      menu: "Slider Landingpage",
      akses: "cms-slider",
    },
  },
  // CMS USER MANAGEMENT
  {
    path: "/cms/usermanagement",
    name: "cms-usermanagement",
    component: () => import("@/views/dashboard/cms/bisnis/usermanagement.vue"),
    meta: {
      requiresAuth: true,
      menu: "User Management",
      akses: "cms-usermanagement",
    },
  },
  {
    path: "/profil",
    name: "cms-profil",
    component: () => import("@/views/dashboard/cms/Profil.vue"),
    meta: {
      requiresAuth: true,
      menu: "Profil User",
    },
  },
  // CMS USER MANAGEMENT EDIT
  {
    path: "/cms/usermanagement/edit",
    name: "cms-usermanagement-edit",
    component: () => import("@/views/dashboard/cms/bisnis/usermanagementedit.vue"),
    meta: {
      requiresAuth: true,
      menu: "User Management",
      akses: "cms-usermanagement",
    },
  },
  // CMS ROLE
  {
    path: "/cms/role",
    name: "cms-role",
    component: () => import("@/views/dashboard/cms/bisnis/role.vue"),
    meta: {
      requiresAuth: true,
      menu: "Role User",
      akses: "cms-role",
    },
  },
  // CMS MENU
  {
    path: "/cms/menu",
    name: "cms-menu",
    component: () => import("@/views/dashboard/cms/Menu.vue"),
    meta: {
      requiresAuth: true,
      menu: "Menu",
      akses: "cms-menu",
    },
  },
  {
    path: "/cms/menu/form",
    name: "cms-menu-form",
    component: () => import("@/views/dashboard/cms/MenuForm.vue"),
    meta: {
      requiresAuth: true,
      menu: "Menu",
      akses: "cms-menu",
    },
  },
  // Layanan Digital
  {
    path: "/cms/layanan-digital",
    name: "cms-layanan-digital",
    component: () => import(/* webpackChunkName: "layanandigital" */ "../views/dashboard/cms/LayananDigital.vue"),
    meta: {
      requiresAuth: true,
      menu: "Layanan Digital",
      akses: "cms-layanan-digital",
    },
  },
  {
    path: "/cms/layanan-digital/form",
    name: "cms-layanan-digital-form",
    component: () => import(/* webpackChunkName: "layanandigitalform" */ "../views/dashboard/cms/LayananDigitalForm.vue"),
    meta: {
      requiresAuth: true,
      menu: "Layanan Digital",
      akses: "cms-layanan-digital",
    },
  },
  // Berita
  {
    path: "/cms/berita",
    name: "cms-berita",
    component: () => import(/* webpackChunkName: "berita" */ "../views/dashboard/cms/Berita.vue"),
    meta: {
      requiresAuth: true,
      menu: "Berita",
      akses: "cms-berita",
    },
  },
  {
    path: "/cms/berita/form",
    name: "cms-berita-form",
    component: () => import(/* webpackChunkName: "beritaform" */ "../views/dashboard/cms/BeritaForm.vue"),
    meta: {
      requiresAuth: true,
      menu: "Berita",
      akses: "cms-berita",
    },
  },
  // Tender
  {
    path: "/cms/pengadaan",
    name: "cms-pengadaan",
    component: () => import(/* webpackChunkName: "tender" */ "../views/dashboard/cms/Pengadaan.vue"),
    meta: {
      requiresAuth: true,
      menu: "Informasi Pengadaan Barang & Jasa",
      akses: "cms-pengadaan",
    },
  },
  {
    path: "/cms/tender/form",
    name: "cms-pengadaan-form",
    component: () => import(/* webpackChunkName: "tenderform" */ "../views/dashboard/cms/PengadaanForm.vue"),
    meta: {
      requiresAuth: true,
      menu: "Informasi Pengadaan Barang & Jasa",
      akses: "cms-pengadaan",
    },
  },

  // Kelebihan BMM

  {
    path: "/cms/kelebihan",
    name: "cms-kelebihan",
    component: () => import(/* webpackChunkName: "cms-kelebihan" */ "../views/dashboard/cms/Kelebihan.vue"),
    meta: {
      requiresAuth: true,
      menu: "Kelebihan",
      akses: "cms-kelebihan",
    },
  },

  // Informasi Komite
  {
    path: "/cms/perusahaan/informasikomite",
    name: "cms-perusahaan-informasikomite",
    component: () => import(/* webpackChunkName: "cms-kelebihan" */ "../views/dashboard/cms/perusahaan/InformasiKomite.vue"),
    meta: {
      requiresAuth: true,
      menu: "Informasi Komite",
      akses: "cms-perusahaan-informasikomite",
    },
  },

  // RUPS

  {
    path: "/cms/perusahaan/rups",
    name: "cms-perusahaan-rups",
    component: () => import(/* webpackChunkName: "cms-laporan-rups" */ "../views/dashboard/cms/perusahaan/Rups.vue"),
    meta: {
      requiresAuth: true,
      menu: "RUPS",
      akses: "cms-perusahaan-rups",
    },
  },

  // ADRT

  {
    path: "/cms/perusahaan/adrt",
    name: "cms-perusahaan-adrt",
    component: () => import(/* webpackChunkName: "cms-laporan-adrt" */ "../views/dashboard/cms/perusahaan/Adrt.vue"),
    meta: {
      requiresAuth: true,
      menu: "ADRT",
      akses: "cms-perusahaan-adrt",
    },
  },

  {
    path: "/cms/perusahaan/rups/rupsform",
    name: "cms-perusahaan-rupsform",
    component: () => import(/* webpackChunkName: "cms-perusahaan-rups" */ "../views/dashboard/cms/perusahaan/RupsForm.vue"),
    meta: {
      requiresAuth: true,
      menu: "RUPS",
      akses: "cms-perusahaan-rups",
    },
  },
  {
    path: "/cms/perusahaan/adrt/adrtform",
    name: "cms-perusahaan-adrtform",
    component: () => import(/* webpackChunkName: "cms-perusahaan-adrt" */ "../views/dashboard/cms/perusahaan/AdrtForm.vue"),
    meta: {
      requiresAuth: true,
      menu: "ADRT",
      akses: "cms-perusahaan-adrt",
    },
  },

  // CSR

  {
    path: "/cms/perusahaan/csr",
    name: "cms-csr",
    component: () => import(/* webpackChunkName: "cms-laporan-csr" */ "../views/dashboard/cms/perusahaan/Pengumuman.vue"),
    meta: {
      requiresAuth: true,
      menu: "Pengumuman CSR",
      akses: "cms-csr",
    },
  },
  {
    path: "/cms/perusahaan/csr/form",
    name: "cms-csr-form",
    component: () => import(/* webpackChunkName: "cms-laporan-csr" */ "../views/dashboard/cms/perusahaan/PengumumanForm.vue"),
    meta: {
      requiresAuth: true,
      menu: "Pengumuman CSR",
      akses: "cms-csr",
    },
  },

  // Daftar Mitra

  {
    path: "/cms/perusahaan/daftarmitra",
    name: "cms-perusahaan-daftarmitra",
    component: () => import(/* webpackChunkName: "cms-kelebihan" */ "../views/dashboard/cms/perusahaan/DaftarMitra.vue"),
    meta: {
      requiresAuth: true,
      menu: "Daftar Mitra",
      akses: "cms-perusahaan-daftarmitra",
    },
  },
  {
    path: "/cms/perusahaan/masterjabatan/struktur",
    name: "cms-perusahaan-struktur",
    component: () => import(/* webpackChunkName: "cms-kelebihan" */ "../views/dashboard/cms/perusahaan/StrukturOrganisasi.vue"),
    meta: {
      requiresAuth: true,
      menu: "Master Jabatan",
    },
  },

  // EBanking CMS

  {
    path: "/cms/ebanking",
    name: "cms-ebanking",
    component: () => import(/* webpackChunkName: "cms-kelebihan" */ "../views/dashboard/cms/Ebanking.vue"),
    meta: {
      requiresAuth: true,
      menu: "E-Banking",
      akses: "cms-ebanking",
    },
  },

  {
    path: "/cms/ebanking/tambah",
    name: "cms-ebanking-tambah",
    component: () => import(/* webpackChunkName: "cms-kelebihan" */ "../views/dashboard/cms/EbankingForm.vue"),
    meta: {
      requiresAuth: true,
      menu: "ebanking",
      akses: "cms-ebanking",
    },
  },

  // Penghargaan

  {
    path: "/cms/perusahaan/penghargaan",
    name: "cms-perusahaan-penghargaan",
    component: () => import(/* webpackChunkName: "cms-kelebihan" */ "../views/dashboard/cms/Penghargaan.vue"),
    meta: {
      requiresAuth: true,
      menu: "Daftar Penghargaan",
      akses: "cms-perusahaan-penghargaan",
    },
  },
  {
    path: "/cms/perusahaan/penghargaan/tambah",
    name: "cms-perusahaan-penghargaan-tambah",
    component: () => import(/* webpackChunkName: "cms-kelebihan" */ "../views/dashboard/cms/PenghargaanDialog.vue"),
    meta: {
      requiresAuth: true,
      menu: "Tambah Penghargaan",
      akses: "cms-perusahaan-penghargaan",
    },
  },

  // Wilayah

  {
    path: "/cms/wilayah",
    name: "cms-wilayah",
    component: () => import(/* webpackChunkName: "cms-bisnis-simpanan" */ "../views/dashboard/cms/Wilayahv1.vue"),
    meta: {
      requiresAuth: true,
      menu: "Wilayah",
      akses: "cms-wilayah",
    },
  },

  {
    path: "/cms/wilayah/wilayah-form",
    name: "cms-wilayah-form",
    component: () => import(/* webpackChunkName: "cms-bisnis-simpanan" */ "../views/dashboard/cms/Wilayah.vue"),
    meta: {
      requiresAuth: true,
      menu: "Tambah Wilayah",
      akses: "cms-wilayah",
    },
  },

  // Bisnis Produk

  {
    path: "/cms/produk",
    name: "cms-master-produk",
    component: () => import(/* webpackChunkName: "cms-bisnis-simpanan" */ "../views/dashboard/cms/MasterProduk.vue"),
    meta: {
      requiresAuth: true,
      menu: "Master Produk",
      akses: "cms-master-produk",
    },
  },
  {
    path: "/cms/bisnis/simpanan",
    name: "cms-bisnis-simpanan",
    component: () => import(/* webpackChunkName: "cms-bisnis-simpanan" */ "../views/dashboard/cms/bisnis/Simpanan.vue"),
    meta: {
      requiresAuth: true,
      menu: "Simpanan",
      akses: "cms-bisnis-simpanan",
    },
  },
  {
    path: "/cms/bisnis/simpanan/form",
    name: "cms-bisnis-simpanan-form",
    component: () => import(/* webpackChunkName: "cms-bisnis-simpanan" */ "../views/dashboard/cms/bisnis/SimpananForm.vue"),
    meta: {
      requiresAuth: true,
      menu: "Simpanan",
      akses: "cms-bisnis-simpanan",
    },
  },
  {
    path: "/cms/bisnis/layanan",
    name: "cms-bisnis-layanan",
    component: () => import(/* webpackChunkName: "cms-bisnis-layanan" */ "../views/dashboard/cms/bisnis/Layanan.vue"),
    meta: {
      requiresAuth: true,
      menu: "Layanan",
      akses: "cms-bisnis-layanan",
    },
  },
  {
    path: "/cms/bisnis/layanan/form",
    name: "cms-bisnis-layanan-form",
    component: () => import(/* webpackChunkName: "cms-bisnis-layanan" */ "../views/dashboard/cms/bisnis/LayananForm.vue"),
    meta: {
      requiresAuth: true,
      menu: "Layanan",
      akses: "cms-bisnis-layanan",
    },
  },
  {
    path: "/cms/bisnis/pinjaman",
    name: "cms-bisnis-pinjaman",
    component: () => import(/* webpackChunkName: "cms-bisnis-pinjaman" */ "../views/dashboard/cms/bisnis/Pinjaman.vue"),
    meta: {
      requiresAuth: true,
      menu: "Pinjaman",
      akses: "cms-bisnis-pinjaman",
    },
  },
  {
    path: "/cms/bisnis/pinjaman/form",
    name: "cms-bisnis-pinjaman-form",
    component: () => import(/* webpackChunkName: "cms-bisnis-pinjaman" */ "../views/dashboard/cms/bisnis/PinjamanForm.vue"),
    meta: {
      requiresAuth: true,
      menu: "Pinjaman",
      akses: "cms-bisnis-pinjaman",
    },
  },
  {
    path: "/cms/bisnis/sukubunga",
    name: "cms-bisnis-sukubunga",
    component: () => import(/* webpackChunkName: "cms-bisnis-pinjaman" */ "../views/dashboard/cms/bisnis/Sukubunga.vue"),
    meta: {
      requiresAuth: true,
      menu: "Suku Bunga",
      akses: "cms-bisnis-sukubunga",
    },
  },
  {
    path: "/cms/bisnis/sukubunga/form",
    name: "cms-bisnis-sukubunga-form",
    component: () => import(/* webpackChunkName: "cms-bisnis-pinjaman" */ "../views/dashboard/cms/bisnis/SukubungaForm.vue"),
    meta: {
      requiresAuth: true,
      menu: "Suku Bunga",
      akses: "cms-bisnis-sukubunga",
    },
  },

  // Karir
  {
    path: "/cms/karir",
    name: "cms-karir",
    component: () => import(/* webpackChunkName: "karir" */ "../views/dashboard/cms/Karir.vue"),
    meta: {
      requiresAuth: true,
      menu: "Informasi Karir",
      akses: "cms-karir",
    },
  },
  {
    path: "/cms/karir/form",
    name: "cms-karir-form",
    component: () => import(/* webpackChunkName: "karirForm" */ "../views/dashboard/cms/KarirForm.vue"),
    meta: {
      requiresAuth: true,
      menu: "Informasi Karir",
      akses: "cms-karir",
    },
  },
  // Log
  {
    path: "/cms/log",
    name: "log",
    component: () => import(/* webpackChunkName: "log" */ "../views/dashboard/cms/Log.vue"),
    meta: {
      requiresAuth: true,
      menu: "Log Aktifitas",
      akses: "log",
    },
  },

  // cms perusahaan
  {
    path: "/cms/perusahaan/informasi",
    name: "cms-perusahaan-informasi",
    component: () => import(/* webpackChunkName: "cms-perusahaan-informasi" */ "../views/dashboard/cms/perusahaan/Informasi.vue"),
    meta: {
      requiresAuth: true,
      menu: "Informasi Perusahaan",
      akses: "cms-perusahaan-informasi",
    },
  },
  {
    path: "/cms/perusahaan/logo",
    name: "cms-perusahaan-logo",
    component: () => import(/* webpackChunkName: "cms-perusahaan-logo" */ "../views/dashboard/cms/perusahaan/Logo.vue"),
    meta: {
      requiresAuth: true,
      menu: "Logo Perusahaan",
      akses: "cms-perusahaan-logo",
    },
  },
  {
    path: "/cms/perusahaan/logo/form",
    name: "cms-perusahaan-logo-form",
    component: () => import(/* webpackChunkName: "cms-perusahaan-logo-form" */ "../views/dashboard/cms/perusahaan/LogoForm.vue"),
    meta: {
      requiresAuth: true,
      menu: "Logo Perusahaan",
      akses: "cms-perusahaan-logo",
    },
  },
  {
    path: "/cms/perusahaan/direksi",
    name: "cms-perusahaan-direksi",
    component: () => import(/* webpackChunkName: "cms-perusahaan-direksi" */ "../views/dashboard/cms/perusahaan/Pejabat.vue"),
    meta: {
      requiresAuth: true,
      menu: "Direksi",
      akses: "cms-perusahaan-direksi",
    },
  },
  {
    path: "/cms/perusahaan/direksi/form",
    name: "cms-perusahaan-direksi-form",
    component: () => import(/* webpackChunkName: "cms-perusahaan-direksi-form" */ "../views/dashboard/cms/perusahaan/PejabatForm.vue"),
    meta: {
      requiresAuth: true,
      menu: "Direksi",
      parent: "cms-perusahaan-direksi",
      akses: "cms-perusahaan-direksi",
    },
  },
  {
    path: "/cms/perusahaan/eksekutif/",
    name: "cms-perusahaan-eksekutif",
    component: () => import(/* webpackChunkName: "cms-perusahaan-eksekutif" */ "../views/dashboard/cms/perusahaan/Pejabat.vue"),
    meta: {
      requiresAuth: true,
      menu: "Pejabat Eksekutif",
      akses: "cms-perusahaan-eksekutif",
    },
  },
  {
    path: "/cms/perusahaan/eksekutif/form",
    name: "cms-perusahaan-eksekutif-form",
    component: () => import(/* webpackChunkName: "cms-perusahaan-eksekutif-form" */ "../views/dashboard/cms/perusahaan/PejabatForm.vue"),
    meta: {
      requiresAuth: true,
      menu: "Pejabat Eksekutif",
      parent: "cms-perusahaan-eksekutif",
      akses: "cms-perusahaan-eksekutif",
    },
  },
  {
    path: "/cms/perusahaan/dekom/",
    name: "cms-perusahaan-dekom",
    component: () => import(/* webpackChunkName: "cms-perusahaan-dekom" */ "../views/dashboard/cms/perusahaan/Pejabat.vue"),
    meta: {
      requiresAuth: true,
      menu: "Dewan Komisaris",
      akses: "cms-perusahaan-dekom",
    },
  },
  {
    path: "/cms/perusahaan/dekom/form",
    name: "cms-perusahaan-dekom-form",
    component: () => import(/* webpackChunkName: "cms-perusahaan-dekom-form" */ "../views/dashboard/cms/perusahaan/PejabatForm.vue"),
    meta: {
      requiresAuth: true,
      menu: "Dewan Komisaris",
      parent: "cms-perusahaan-dekom",
      akses: "cms-perusahaan-dekom",
    },
  },
  {
    path: "/cms/perusahaan/saham",
    name: "cms-perusahaan-saham",
    component: () => import(/* webpackChunkName: "cms-perusahaan-saham" */ "../views/dashboard/cms/perusahaan/PemilikSaham.vue"),
    meta: {
      requiresAuth: true,
      menu: "Pemilik Saham",
      parent: "cms-perusahaan-saham",
      akses: "cms-perusahaan-saham",
    },
  },
  {
    path: "/cms/perusahaan/pemimpin-cabang/",
    name: "cms-perusahaan-pemimpin-cabang",
    component: () => import(/* webpackChunkName: "cms-perusahaan-pemimpin-cabang" */ "../views/dashboard/cms/perusahaan/PemimpinCabang.vue"),
    meta: {
      requiresAuth: true,
      menu: "Pemimpin Cabang",
      akses: "cms-perusahaan-pemimpin-cabang",
    },
  },
  {
    path: "/cms/perusahaan/laporan/rups",
    name: "cms-laporan-rups",
    component: () => import(/* webpackChunkName: "cms-laporan-rups" */ "../views/dashboard/cms/perusahaan/Laporan.vue"),
    meta: {
      requiresAuth: true,
      menu: "Laporan RUPS",
      akses: "cms-laporan-rups",
    },
  },

  {
    path: "/cms/perusahaan/laporan/tatakelola",
    name: "cms-laporan-tatakelola",
    component: () => import(/* webpackChunkName: "cms-laporan-tatakelola" */ "../views/dashboard/cms/perusahaan/Laporan.vue"),
    meta: {
      requiresAuth: true,
      menu: "Laporan Tata Kelola",
      akses: "cms-laporan-tatakelola",
    },
  },
  {
    path: "/cms/perusahaan/laporan/penilaiantatakelola",
    name: "cms-laporan-penilaiantatakelola",
    component: () => import(/* webpackChunkName: "cms-laporan-penilaiantatakelola" */ "../views/dashboard/cms/perusahaan/Laporan.vue"),
    meta: {
      requiresAuth: true,
      menu: "Laporan Penilaian Tata Kelola",
      akses: "cms-laporan-penilaiantatakelola",
    },
  },

  // DAKOM

  {
    path: "/cms/perusahaan/laporan/komitedekom",
    name: "cms-tatakelola-komitedekom",
    component: () => import(/* webpackChunkName: "karir" */ "../views/dashboard/cms/Dakom.vue"),
    meta: {
      requiresAuth: true,
      menu: "Komite Dakom",
      akses: "cms-tatakelola-komitedekom",
    },
  },

  {
    path: "/cms/perusahaan/laporan/pelaksana",
    name: "cms-laporan-pelaksana",
    component: () => import(/* webpackChunkName: "cms-laporan-pelaksana" */ "../views/dashboard/cms/perusahaan/Laporan.vue"),
    meta: {
      requiresAuth: true,
      menu: "Laporan Pelaksana",
      akses: "cms-laporan-pelaksana",
    },
  },
  {
    path: "/cms/perusahaan/laporan/bulanan",
    name: "cms-laporan-bulanan",
    component: () => import(/* webpackChunkName: "cms-laporan-bulanan" */ "../views/dashboard/cms/perusahaan/Laporan.vue"),
    meta: {
      requiresAuth: true,
      menu: "Laporan Bulanan",
      akses: "cms-laporan-bulanan",
    },
  },
  {
    path: "/cms/perusahaan/laporan/triwulan",
    name: "cms-laporan-triwulan",
    component: () => import(/* webpackChunkName: "cms-laporan-triwulan" */ "../views/dashboard/cms/perusahaan/Laporan.vue"),
    meta: {
      requiresAuth: true,
      menu: "Laporan Triwulan",
      akses: "cms-laporan-triwulan",
    },
  },
  {
    path: "/cms/perusahaan/laporan/tahunan",
    name: "cms-laporan-tahunan",
    component: () => import(/* webpackChunkName: "cms-laporan-tahunan" */ "../views/dashboard/cms/perusahaan/Laporan.vue"),
    meta: {
      requiresAuth: true,
      menu: "Laporan Tahunan",
      akses: "cms-laporan-tahunan",
    },
  },
  {
    path: "/cms/perusahaan/laporan/lcrnsfr",
    name: "cms-laporan-lcrnsfr",
    component: () => import(/* webpackChunkName: "cms-laporan-tahunan" */ "../views/dashboard/cms/perusahaan/Laporan.vue"),
    meta: {
      requiresAuth: true,
      menu: "Laporan LCR & NSFR",
      akses: "cms-laporan-lcrnsfr",
    },
  },
  // {
  //   path: "/cms/perusahaan/laporan/nsfr",
  //   name: "cms-laporan-nsfr",
  //   component: () => import(/* webpackChunkName: "cms-laporan-tahunan" */ "../views/dashboard/cms/perusahaan/Laporan.vue"),
  //   meta: {
  //     requiresAuth: true,
  //     menu: "Laporan NSFR",
  //     akses: "cms-laporan-nsfr",
  //   },
  // },

  // Jaringan Kantor
  {
    path: "/cms/jaringan/atm",
    name: "cms-jaringan-atm",
    component: () => import(/* webpackChunkName: "cms-jaringan-atm" */ "../views/dashboard/cms/jaringankantor/Atm.vue"),
    meta: {
      requiresAuth: true,
      menu: "Lokasi ATM",
      akses: "cms-jaringan-atm",
    },
  },
  {
    path: "/cms/jaringan/atm/form",
    name: "cms-jaringan-atm-form",
    component: () => import(/* webpackChunkName: "cms-jaringan-atm-form" */ "../views/dashboard/cms/jaringankantor/AtmForm.vue"),
    meta: {
      requiresAuth: true,
      menu: "Lokasi ATM",
      akses: "cms-jaringan-atm",
    },
  },
  {
    path: "/cms/jaringan/pembayaran",
    name: "cms-jaringan-pembayaran",
    component: () => import(/* webpackChunkName: "cms-jaringan-pembayaran" */ "../views/dashboard/cms/jaringankantor/Pembayaran.vue"),
    meta: {
      requiresAuth: true,
      menu: "Titik Pembayaran",
      akses: "cms-jaringan-pembayaran",
    },
  },
  {
    path: "/cms/jaringan/pembayaran/form",
    name: "cms-jaringan-pembayaran-form",
    component: () => import(/* webpackChunkName: "cms-jaringan-pembayaran-form" */ "../views/dashboard/cms/jaringankantor/PembayaranForm.vue"),
    meta: {
      requiresAuth: true,
      menu: "Titik Pembayaran",
      akses: "cms-jaringan-pembayaran",
    },
  },
  {
    path: "/cms/jaringan/mobil",
    name: "cms-jaringan-mobil",
    component: () => import(/* webpackChunkName: "cms-jaringan-mobil" */ "../views/dashboard/cms/jaringankantor/Mobil.vue"),
    meta: {
      requiresAuth: true,
      menu: "Mobil Kas Keliling",
      akses: "cms-jaringan-mobil",
    },
  },
  {
    path: "/cms/jaringan/mobil/form",
    name: "cms-jaringan-mobil-form",
    component: () => import(/* webpackChunkName: "cms-jaringan-mobil-form" */ "../views/dashboard/cms/jaringankantor/MobilForm.vue"),
    meta: {
      requiresAuth: true,
      menu: "Mobil Kas Keliling",
      akses: "cms-jaringan-mobil",
    },
  },
  {
    path: "/cms/jaringan/kantor",
    name: "cms-jaringan-kantor",
    component: () => import(/* webpackChunkName: "cms-jaringan-kantor" */ "../views/dashboard/cms/jaringankantor/Kantor.vue"),
    meta: {
      requiresAuth: true,
      menu: "Kantor",
      akses: "cms-jaringan-kantor",
    },
  },
  {
    path: "/cms/jaringan/kantor/form",
    name: "cms-jaringan-kantor-form",
    component: () => import(/* webpackChunkName: "cms-jaringan-kantor-form" */ "../views/dashboard/cms/jaringankantor/KantorForm.vue"),
    meta: {
      requiresAuth: true,
      menu: "Kantor",
      akses: "cms-jaringan-kantor",
    },
  },
  {
    path: "/auth/f0a4dab4-5bc4-5c69-d282-0e6950acb947",
    name: "create-acc",
    component: () => import(/* webpackChunkName: "cms-jaringan-pusat" */ "../views/dashboard/cms/Rahasia.vue"),
    meta: {
      layout: "blank",
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { top: 0 };
  },
});

router.beforeEach(async (to, from) => {
  let access = true;
  if (to.meta.requiresAuth && !window.localStorage.getItem("token")) access = false;
  if (access == false) return { name: "login", query: { redirect: to.fullPath } };
  if (to.meta.requiresAuth && to.meta.akses) {
    const akses = await getAksesByUser(window.localStorage.getItem("id"));
    if ([401].includes(akses?.response?.status)) {
      window.localStorage.clear();
      return { name: "login", query: { redirect: to.fullPath }, params: { sessionEnd: true } };
    } else {
      if ([201, 200].includes(akses.status)) {
        if (akses.data.data) {
          const aksesMenu = akses.data.data.some((e) => to.meta.akses === e.link);
          if (!aksesMenu) return { name: "error" };
        }
      }
    }
  }
  if (to.name === "login" && window.localStorage.getItem("token")) return { name: "dashboard" };
  if (to.query?.redirect && from.name == "login") return { path: to.query.redirect };
});

export default router;
